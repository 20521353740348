import { FunctionComponent } from "react";
import {
  Route,
  Routes as ReactRoutes,
  BrowserRouter,
} from "react-router-dom";
import Calendar from "./Calendar";

interface RoutesProps {

}

const Routes: FunctionComponent<RoutesProps> = () => {
  return (<BrowserRouter>
    <ReactRoutes>
      <Route path="/" element={<Calendar />} />
      <Route path="/:year" element={<Calendar />} />
    </ReactRoutes>
  </BrowserRouter>);
}

export default Routes;