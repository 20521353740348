import React, { FunctionComponent } from "react";

import Routes from "./Routes";

interface AppProps {
  
}
 
const App: FunctionComponent<AppProps> = () => {
  return (<Routes />);
}
 
export default App;