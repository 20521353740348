import React, { useMemo } from 'react';
import MonthCalendar, { HolidayType } from './components/MonthCalendar';
import dayjs from 'dayjs';
import plan from './holiday_plan.json';
import Footer from './components/Footer';
import ListHolidays from './components/ListHolidays';
import { useParams } from 'react-router-dom';


function Calendar() {
  const params = useParams<{ year?: string }>();
  const months = Array.from(new Array(12).keys());
  let today = dayjs();
  if (params.year) {
    today = today.set('year', +params.year);
  }
  const currentYear = today.format('YYYY');
  let diff = 999;
  let earliestPlan = '';
  const holidayPlan = useMemo(() => {
    // @ts-ignore
    const yearPlan = plan[currentYear] || {};
    const res = new Map<string, { type: HolidayType, name?: string }>();

    Object.entries(yearPlan).forEach(([dayPlan, value]) => {
      const daysDiff = dayjs(dayPlan).diff(today, 'days');
      if (diff > daysDiff && daysDiff >= 0) {
        diff = daysDiff;
        earliestPlan = dayPlan;
      }
      res.set(dayPlan, value as { type: HolidayType, name?: string });
    })
    return res;
  }, [currentYear])


  return (
    <div className="App container mx-auto">
      <header className="App-header mt-4 mx-4">
        <h1 className='text-3xl font-semibold'>
          Maxa ledigheten {currentYear}
        </h1>
      </header>
      <div className='flex items-center mt-4 mx-4 flex-col lg:flex-row'>
        <div className='flex items-center'>
          <div className='text-center bg-red-500 text-white w-10 h-10 mr-1'></div> Ledig
          <div className='text-center bg-red-300 text-white w-10 h-10 ml-2 mr-1'></div> Ta ledigt
        </div>
        <div className='lg:ml-4 font-semibold lg:border-l-2 lg:pl-4'>Den närmaste ledigheten är {earliestPlan}</div>
      </div>

      <div className='grid lg:grid-cols-4 gap-4 mt-4 mb-4 mx-4'>
        {months.map(month => (
          <MonthCalendar year={today.get('year')} month={month} key={month} holidayPlan={holidayPlan} />
        ))}
      </div>
      <ListHolidays holidayPlan={holidayPlan} />
      <Footer />
    </div>
  );
}

export default Calendar;
