import { FunctionComponent } from "react";
import { HolidayType } from "./MonthCalendar";

interface ListHolidaysProps {
  holidayPlan: Map<string, { type: HolidayType, name?: string }>;
}

const ListHolidays: FunctionComponent<ListHolidaysProps> = ({ holidayPlan }) => {
  const redDays = Array.from(holidayPlan.entries()).filter(([key, value]) => value.type === 'Holiday' && value.name);
  return (
    <div className="mt-8 mx-4 lg:w-1/2">
      <h4 className="text-lg font-bold">Röda dagar</h4>
      {redDays.map(([key, value]) => (
        <div className="border-b text-gray-600 py-2" key={key}>{key} <span className="ml-4">{value.name}</span></div>
      ))}
    </div>
  );
};

export default ListHolidays;
