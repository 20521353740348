import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import { FunctionComponent } from "react";

export type HolidayType = "Holiday" | "Vacation";

interface MonthCalendarProps {
  month: number;
  holidayPlan: Map<string, { type: HolidayType, name?: string }>;
  year: number;
}

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const getDate = (refDate: Dayjs, day: number) => refDate.add(day, 'day').format('YYYY-MM-DD');

const getStyleForDay = (type: HolidayType, isToday: boolean) => {
  if (type === 'Holiday') return 'bg-red-500 text-white';
  if (type === 'Vacation') return 'bg-red-300 text-white';
  return '';
}

const MonthCalendar: FunctionComponent<MonthCalendarProps> = ({
  month,
  holidayPlan,
  year,
}) => {
  const today = dayjs().set('year', year);
  const startWeek = today.startOf("isoWeek");
  const weekDays = Array.from(new Array(7).keys()).map((index) => {
    return startWeek.add(index, "day");
  });

  const isCurrentYear = dayjs().get('year') === year;
  const isCurrentMonth = today.get('month') === month && isCurrentYear;
  const startOfMonth = today.set("month", month).startOf("month");
  const startOfFirstWeek = startOfMonth.startOf("isoWeek");
  const daysToFirstDay = startOfMonth.diff(startOfFirstWeek, "day");

  const daysToPrepend = Array.from(new Array(daysToFirstDay).keys());
  const daysInMonth = Array.from(new Array(startOfMonth.daysInMonth()).keys());
  const todayPresentation = today.format('YYYY-MM-DD');
  return (
    <div className="h-full flex flex-col">
      <h2 className="font-semibold">{startOfMonth.format("MMM")}</h2>
      <div className={`grid grid-cols-7 mt-2 rounded-md border overflow-hidden flex-1 ${isCurrentMonth ? 'border-indigo-600' : ''}`}>
        {weekDays.map((weekDay, index) => (
          <div className="text-center" key={`weekday_${index}`}>
            {weekDay.format("ddd")}
          </div>
        ))}
        {daysToPrepend.map((day) => (
          <div key={`prepend_${day}`}></div>
        ))}
        {daysInMonth.map((day) => {
          const key = getDate(startOfMonth, day);
          const isPlanned = holidayPlan.has(key);
          const isToday = key === todayPresentation && isCurrentYear;
          return (
            <div
              className={`text-center ${isToday ? "border border-indigo-500" : "border border-transparent"} ${isPlanned ? getStyleForDay(holidayPlan.get(key)!.type, isToday) : ''}`}
              key={key}
            >
              {day + 1}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default MonthCalendar;
